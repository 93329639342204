<template>
  <div class="home">
    <CustomHeader ref="header"></CustomHeader>
    <div class="banner-bg">
      <div class="slogan">
        <p class="p1">USE SCENARIOS</p>
        <p class="p2">智慧城市解决方案</p>
      </div>
      <div class="banner-title opcity">
        <div class="core">
          <div @click="view('#environment')">环境治理</div>
          <div @click="view('#gongan')">智慧公安</div>
          <div @click="view('#firecontrol')">消防应急</div>
          <div @click="view('#traffic')">交通管理</div>
          <div @click="view('#Infrastructure')">智慧基建</div>
        </div>
      </div>
    </div>
    <!-- 用途场景 -->
    <div class="bg-c">
      <div class="pt-8">
        <h1 class="h1" data-aos="fade-down">用途场景</h1>
        <Title back="#eff5ff" title="Application Scenario"></Title>
      </div>
      <!-- 视频 -->
      <div class="video">
        <video v-if="viewFlag" class="opcity" ref="videoPlayer" webkit-playsinline playsinline x5-playsinline muted autoplay loop src="https://cdn.aihangtec.com/index/video/city.mp4"></video>
        <img class="unopcity" src="https://cdn.aihangtec.com/index/video/city.gif" />
      </div>
    </div>

    <div class="bg-w p-6" id="environment">
      <div class="core card1">
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/a1.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/ma1.png" />
        </div>
        <div class="card-inner">
          <div class="card-title">
            <div class="card-num" data-aos="flip-left">01</div>
            <div class="line"></div>
            <div>环境治理</div>
          </div>
          <div class="card-text">
            无人机通过GIS遥感技术快速获取地理、资源、环境等空间遥感信息，完成遥感数据采集，对市容市貌、违规搭建、城市河道、植物覆盖、排污监测、环境应急处理等模块进行快速响应处理和应用分析。结合分析数据完成各模块环境建模及科技信息化的综合数据管理工作，具有机动、快速、经济等优势。
          </div>
          <div class="card-type" data-aos="fade-right">
            <div>· 水质检测</div>
            <div>· 排污监测</div>
            <div>· 河道巡检</div>
            <div>· 水政执法</div>
            <div>· 紧急救援</div>
            <div>· 生态资源监测</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-f p-6" id="gongan">
      <div class="core card1">
        <div class="card-inner">
          <div class="right">
            <div class="card-title">
              <div class="card-num" data-aos="flip-left">02</div>
              <div class="line"></div>
              <div>智慧公安</div>
            </div>
            <div class="card-text">
              无人机应用于警方安保巡防，不受地形地貌、视角盲区限制，具备直观、准确、快速的特点，使得巡防效率和响应速度得到极大提高，长远看可以节省大量人力、物力。挂载应用可以实现空中侦察、防御和打击功能，大大提高警务实战中的执法效果、效率，最大限度的降低执法风险，提升综合管理能力和执法力度,保障人民生产生活的安全有序进行。
            </div>
            <div class="card-type two" data-aos="fade-left">
              <div>· 侦查图传</div>
              <div>· 警务打击</div>
              <div>· 服务救援</div>
              <div>· 排爆安检</div>
              <div>· 社会管理</div>
              <div>· 智慧巡管</div>
              <div class="opcity">· 警用无人巡逻车</div>
            </div>
          </div>
        </div>
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/a2.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/ma2.png" />
        </div>
      </div>
    </div>

    <div class="bg-w p-6" id="firecontrol">
      <div class="core card1">
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/a3.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/ma3.png" />
        </div>
        <div class="card-inner">
          <div class="card-title">
            <div class="card-num" data-aos="flip-left">03</div>
            <div class="line"></div>
            <div>消防应急</div>
          </div>
          <div class="card-text">
            消防应急指挥中可采用特种材料无人机，同时搭载安全防火的动力系统，数字云结合GIS，精准定位火点，多机一键式回收，清晰成像的高清摄像头可实时回传火点信息数据，即时完成火警监控及侦查工作。地质灾害救援中，无人机可空投救援物资，搭载通信设备变身临时通信基站恢复通信，为救援提供有效保障。
          </div>
          <div class="card-type" data-aos="fade-right">
            <div>· 灾情侦查</div>
            <div>· 监控追踪</div>
            <div>· 辅助救援</div>
            <div>· 辅助监督</div>
            <div>· 通信中继</div>
            <div>· 物资投送</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-f p-6" id="traffic">
      <div class="core card1">
        <div class="card-inner">
          <div class="right">
            <div class="card-title">
              <div class="card-num" data-aos="flip-left">04</div>
              <div class="line"></div>
              <div>交通管理</div>
            </div>
            <div class="card-text">
              无人机作为新型终端，通过智能感知，抓拍识别智能分析，实现交通疏导、缉查布控、道路侦察等，并回传数据可实现三维建模，公路资产电子化建档形成数字化沙盘，同时补充交警智能非现场执法的原始数据。
            </div>
            <div class="card-type" data-aos="fade-left">
              <div>· 定点巡逻</div>
              <div>· 现场抓拍</div>
              <div>· 违停喊话</div>
              <div>· 交通安保</div>
              <div>· 稽查取证</div>
              <div>· 车牌查验</div>
              <div>· 公路巡查</div>
              <div>· 桥梁巡查</div>
            </div>
          </div>
        </div>
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/a4.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/ma4.png" />
        </div>
      </div>
    </div>

    <div class="bg-w p-6" id="Infrastructure">
      <div class="core card1">
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/a5.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/ma5.png" />
        </div>
        <div class="card-inner">
          <div class="card-title">
            <div class="card-num" data-aos="flip-left">05</div>
            <div class="line"></div>
            <div>智慧基建</div>
          </div>
          <div class="card-text">
            “智慧基建”平台融入无人机技术，能够现场全方位智能管控城市及行业应用建设；主要建设内容包含无人机智能管理、现场作业管理、即时通讯、全景可视化、风险管控等业务功能，构建适用全行业全场景的“一站式”基建现场管理信息工程方案。
          </div>
          <div class="card-type" data-aos="fade-right">
            <div>· 无人机三维建模</div>
            <div>· 自动测算青赔面积</div>
            <div>· 深基坑围挡监测</div>
            <div>· 交跨物智能识别</div>
            <div>· 施工进度分析</div>
            <div>· 导线弧垂监测</div>
            <div>· 现场违建告警</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 平台特点 -->
    <div>
      <div class="main pt-8">
        <h1 class="h1" data-aos="fade-up">平台特点</h1>
        <Title title="Platform characteristics"></Title>
        <div class="core list-wrap mt-2">
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/z1.png" /></div>
            <div>
              <p class="title">三维建模及障碍分析</p>
            </div>
          </div>
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/z2.png" /></div>
            <div>
              <p class="title">桥梁检测</p>
            </div>
          </div>
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/z3.png" /></div>
            <div>
              <p class="title">水污染图像识别</p>
            </div>
          </div>
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/z4.png" /></div>
            <div>
              <p class="title">水华及大型漂浮物识别</p>
            </div>
          </div>
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/z5.png" /></div>
            <div>
              <p class="title">警用无人巡逻车</p>
            </div>
          </div>
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/z6.png" /></div>
            <div>
              <p class="title">物资投送</p>
            </div>
          </div>
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/z7.png" /></div>
            <div>
              <p class="title">车辆识别及流量统计</p>
            </div>
          </div>
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/z8.png" /></div>
            <div>
              <p class="title">车牌识别</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="pt-8 mb-8">
      <h1 class="h1" data-aos="fade-up">客户认可</h1>
      <Title title="Customer Recognition"></Title>
      <CustomSwiper />
    </div>
    <Fixed class="opcity"></Fixed>
    <CustomFooter></CustomFooter>
  </div>
</template>

<script>
import AOS from "aos"
import { scrollInto } from "../../utils/util"
export default {
  data() {
    return {
      viewFlag: true
    }
  },
  mounted() {
    AOS.init()
    if (document.body.clientWidth < 780) {
      this.viewFlag = false
    } else {
      scrollInto()
    }
  },
  methods: {
    view(link) {
      scrollInto(link)
    },
    custom() {
      this.$router.push("/custom")
      document.documentElement.scrollTop = 0
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../style/plan.scss";
.banner-bg {
  background-image: url(https://cdn.aihangtec.com/index/image/plan/banner.png);
}
.card-type {
  & > div {
    @media screen and (min-width: 540px) {
      width: 25% !important;
      margin-top: 24px;
    }
  }
}
</style>
